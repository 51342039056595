import { createSlice } from "@reduxjs/toolkit";

const bankingTransactionSlice = createSlice({
    name: 'gateway',
    initialState: {
        gatewayItem: [],
    },
    reducers: {
        setGatewayItem: (state, action) => {
            state.gatewayItem = action.payload;
        },
    }
})

export const gatewayItemData = state => state.gateway.gatewayItem;
export const { setGatewayItem } = bankingTransactionSlice.actions;

export default bankingTransactionSlice.reducer;